import React from 'react';
import CountUp from 'react-countup';
import imagePoint from '../../img/point-white.png';
import './stats.style.css';

const StatsSection = () => {
    return (
        <div className='stats'>
            <div className="container">
                <div className="row py-5 px-2">
                    <div className="col-12 col-sm-3">
                        <img src={imagePoint} />
                        <ul className='pt-3'>
                            <li><CountUp end={30} enableScrollSpy={true} suffix="M+"/></li>    
                            <li><h4>Personas</h4></li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-3">
                        <img src={imagePoint} />
                        <ul className='pt-3'>
                            <li><CountUp end={10000} enableScrollSpy={true} separator="." suffix="+"/></li>
                            <li><h4>Datos <br />actualizados <br />por día</h4></li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-3">
                        <img src={imagePoint} />
                        <ul className='pt-3'>
                            <li><CountUp end={150} enableScrollSpy={true} suffix="+"/></li>
                            <li><h4>Empresas <br />atendidas</h4></li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-3">
                        <img src={imagePoint} />
                        <ul className='pt-3'>
                            <li><CountUp end={99} enableScrollSpy={true} suffix="%"/></li>
                            <li><h4>Confiabilidad <br /> en los datos</h4></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatsSection;
