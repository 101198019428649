
import React from 'react';
import Header from '../components/header/header.component';
import Content from '../components/content/content.component';
import ServicesBanner from '../components/content/services-banner.component';
import ServicesDescription from '../components/content/services-description.component';
import OurPhasesSeparator from '../components/content/our-phases-separator.component';
import ServicesPrices from '../components/content/services-prices.component';
import Footer from '../components/footer/footer.component';

function ServicesPage() {

  return (
    <>
      <Header/>
      <ServicesBanner/>
      <ServicesDescription />
      <ServicesPrices />
      <OurPhasesSeparator />
      <Footer/>
    </>
  );
}


export default ServicesPage;
