
import React from 'react';
import Header from '../components/header/header.component';
import TermsDescription from '../components/content/terms-description.component';
import Footer from '../components/footer/footer.component';

function TermsAndConditions() {

    return (
        <>
            <Header />
            <TermsDescription />
            <Footer />
        </>
    );
}


export default TermsAndConditions;
