import React from 'react';
import ContactForm from './contact-form/contact-form.component';
import backgroundImage from '../../img/fondo-banner-home.jpg';
import './banner-home.style.css';

const BannerHome = () => {
    return (
        <div className="banner-home">
            <div className="container pb-5">
                <div className="row py-5">
                    <div className="col-12 col-sm-7">
                        <h1 className='pt-5'>Obtené datos de calidad</h1>
                        <h2 className='py-5'>¡Fácil y rápido!</h2>
                        <h3 className='pb-5'>Brindamos datos de contacto<br/>
                            de tu público objetivo para que<br />
                            puedas convertirlo en clientes.</h3>
                    </div>
                    <div className="col-5 pt-5 d-none d-sm-block">
                        <h4>Contactanos</h4>
                        <h3 className='py-4'>¡Probá tu base de datos!</h3>
                        <ContactForm/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerHome;
