import React from 'react';
import './footer.style.css';
import logoGray from '../../img/logo-gray.png';
import data from '../../data/data.json';
import { Link } from 'react-router-dom';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-5 py-5 pe-5">
                        <Link to="/">
                          <img src={logoGray} alt="" />
                        </Link>      
                        <a href={'https://api.whatsapp.com/send?phone=' + data.telephone_min} className='d-block py-4'>{data.telephone}</a>
                        <p>Atención al cliente {data.email}</p>
                    </div>
                    <div className="col-12 col-sm-3  py-5">
                        <h4>LINKS</h4>
                        <ul className='ps-0'>
                            <li><Link className="nav-link" to="/#top">Inicio</Link></li>
                            <li><Link className="nav-link" to="/servicios#top">Servicios</Link></li>
                            <li><Link className="nav-link" to="/nuestas-fases#top">Nuestras 5 Fases</Link></li>
                            <li><Link className="nav-link" to="/contacto#top">Contacto</Link></li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-3 py-5">
                        <ul>
                            <li><Link to="/politica-de-privacidad#top">Política de privacidad de datos</Link></li>
                            <li><Link to="/terminos-y-condiciones#top">Términos y condiciones de uso</Link></li>
                        </ul>
                    </div>

                </div>
            </div>
            <section className='copyright'>
                <hr />
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h5 className='d-inline'>©{currentYear} Fase 5 - Todos los derechos reservados</h5>
                            <a href="#top" className="arrow-up float-end" title="Go to top">&#8679;</a>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    );
};


export default Footer;
