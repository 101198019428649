import React from 'react';
import startImage from '../../img/start.svg';
import predictionImage from '../../img/prediction.svg';
import './stacks.style.css';

const StacksSection = () => {
    return (
        <div className='container stacks py-5'>
            <div className='row'>
                <div className="col-12 col-sm-6">
                    <h2 className='pt-5 pb-4'>Encontramos datos precisos sobre tu segmento de mercado</h2>
                    <p>Generamos campañas de marketing precisas para la captación de datos, entendemos tu necesidad de mercado y creamos un datawarehouse a medida. Ingestamos con datos que dispongas de tu negocio transformándolos y creando valor en el resultado. Además somos tu proveedor de datos ya tratados y segmentados para tu campaña.</p>
                </div>
                <div className="col-12 col-sm-6 pt-5  mx-auto">
                    <img src={startImage} alt="" className='img-fluid w-75 px-5' />
                </div>
            </div>
            <div className='row'>
                <div className="col-12 col-sm-6">
                    <h2 className='pt-5 pb-4'>Big Data</h2>
                    <p>Implementamos software de big data con tableros personalizados para analizar volúmenes de información de tu datawarehouse que creamos a tu medida. Podrás tomar decisiones a través de herramientas visuales que cruzan la información con sus múltiples aristas.</p>
                </div>
                <div className="col-12 col-sm-6 pt-5 mx-auto">
                    <img src={predictionImage} alt="" className='img-fluid w-75 px-5' />
                </div>
            </div>
        </div>
    );
};

export default StacksSection;
