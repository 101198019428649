import React from 'react';
import BannerHome from './banner-home.component';
import IntroductionSection from './introduction.component';
import StatsSection from './stats.component';
import StacksSection from './stacks.component';

const Content = () => {
  return (
    <>
      <BannerHome />
      <IntroductionSection/>
      <StatsSection/>
      <StacksSection/>
    </>
    );
};

export default Content;
