import React from 'react';
import './our-phases-team.component.style.css';
import imageOurPhases from '../../img/our-phases.svg'

const OurPhasesTeam = () => {
    return (

        <div className="our-phases-team">
            <div className="row h-100">
                <div className="col-12 col-sm-6 p-5 d-flex align-items-center">
                    <h2 className='text-center mx-auto'>Nuestro equipo</h2>
                </div>
                <div className="col-12 col-sm-6 block p-5  align-items-center">
                    <h3 className='pt-5 pb-3'>Somos profesionales en el campo de programación y análisis de datos</h3>
                    <p className='pt-3'>Nos encontramos en Argentina con más de 10 años de experiencia en el sector.</p>
                    <p className='pt-3'>Como especialistas en tecnología y con experiencia en comercio, buscamos optimizar el arte de la captación de leads, con el fin de convertir información en oportunidades de negocio tangibles para nuestros clientes.</p>
                    <p className='pt-3 pb-5'>¡Te invitamos a sumarte a la era de los negocios inteligentes!</p>
                </div>
            </div>
        </div>
    );
};

export default OurPhasesTeam;
