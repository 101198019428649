import React from 'react';
import './services-prices.style.css';
const ServicesPrices = () => {
    return (

        <div className="services-prices container">
            <div className="row">
                <div className="col-12">
                    <h2 className='text-center mx-auto py-5'>Comenzá ahora</h2>
                </div>
            </div>
            <div className="row pb-5">
                <div className="col-12 col-sm-4 p-3">
                    <div className='prices-card p-4'>
                        <h3 className='py-3'>Fase 5 Free</h3>
                        <p>Para pequeños proyectos de aprendizaje, experimentación y creación de una primera base de datos, hasta 2.000 contactos.</p>
                        <h4 className='py-4'>u$s 0</h4>
                        <a href="/contacto" className='btn btn-primary w-100'>Comenzar gratis</a>
                        <h5 className='pt-2'>*No requiere de tarjeta de crédito</h5>
                    </div>
                </div>
                <div className="col-12 col-sm-4 p-3">
                    <div className='prices-card p-4'>
                        <h3 className='py-3'>Fase 5 Profesional</h3>
                        <p>Para negocios de mediana escala que requieren explotar su base de contactos de manera inteligente, hasta 500.000 contactos.</p>
                        <h4 className='py-4'>u$s 65/mes</h4>
                        <a href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2HM43357JJ5603123MWFEW6I" target='_blank' className='btn btn-primary w-100 mb-4'>Suscribirme</a>
                        
                    </div>
                </div>
                <div className="col-12 col-sm-4 p-3">
                    <div className='prices-card p-4'>
                        <h3 className='py-3'>Fase 5 Empresa</h3>
                        <p>Para negocios que requieren de un ejecutivo de cuenta asignado para obtener datos inteligentes a medida, sin límite de contactos.</p>
                        <h4 className='py-4'>A medida</h4>
                        <a href='https://calendly.com/sistemas-fasecinco/30min' target='_blank' className='btn btn-primary w-100 mb-4'>Agendar videollamada</a>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ServicesPrices;
