
import React from 'react';
import Header from '../components/header/header.component';
import Footer from '../components/footer/footer.component';
import BannerOurPhases from '../components/content/banner-our-phases.component';
import ContentOurPhases from '../components/content/our-phases-content.component';
import OurPhasesSeparator from '../components/content/our-phases-separator.component';
import OurPhasesTeam from '../components/content/our-phases-team.component';

function OurPhases() {

  return (
    <>
      <Header/>
      <BannerOurPhases/>
      <ContentOurPhases />
      <OurPhasesSeparator />
      <OurPhasesTeam />
      <Footer/>
    </>
  );
}


export default OurPhases;
