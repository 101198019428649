import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/home-page';
import OurPhases from './pages/our-phases-page';
import ServicesPage from './pages/services-page';
import BlogPage from './pages/blog-page';
import ContactPage from './pages/contact-page';
import TermsAndConditionsPage from './pages/terms-and-conditions';
import PrivacyPolicyPage from './pages/privacy-policy-page';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/nuestas-fases" element={<OurPhases />} />
        <Route path="/servicios" element={<ServicesPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/contacto" element={<ContactPage />} />
        <Route path="/terminos-y-condiciones" element={<TermsAndConditionsPage />} />
        <Route path="/politica-de-privacidad" element={<PrivacyPolicyPage />} />
      </Routes>
    </Router>
  );
};


export default App;
