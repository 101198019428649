import React, { useState, useEffect } from 'react';
import endpoints from '../../../data/endpoints.json'
import axios from 'axios';

// Optionally, separate styles into a separate file (e.g., ContactForm.css)
const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  select: {
    width: '100%',
    fontSize: '16px',
  },
  button: {
    width: '100%',
    marginTop: '16px',
  },
};

const handlePostRequest = async (endpoint, postData) => {
  try {
    const response = await axios.post(endpoint, postData, {
      headers: {
        'Content-Type': 'application/json'
      },
    });

    console.log('Response:', response.data);
    // Handle the response as needed
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    // Handle errors
    throw error;
  }
};


const ContactForm = () => {
  const [formData, setFormData] = useState({
    txtName: '',
    txtEmail: '',
    txtWhatsapp: '',
    txtCompany: '',
    txtWeb: '',
  });


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    handlePostRequest(endpoints.create_web_contact, JSON.stringify(formData))
    
    /*try {
      const response = await fetch(endpoints.create_web_contact, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      // Handle successful response (e.g., show confirmation message)
      console.log('Submission successful!', data);
    } catch (error) {
      // Handle errors (e.g., show error message)
      console.error('Error submitting form:', error);
    }
*/

  };

  return (
    <form onSubmit={handleSubmit} className="row" >
      <div className="col-12">
        <input
          type="text"
          id="txtName"
          name="txtName"
          className="form-control mb-4"
          autocomplete="off"
          placeholder="NOMBRE"
          value={formData.txtName}
          onChange={handleChange}
        />
      </div>
      <div className="col-6">
        <input
          type="email"
          id="txtEmail"
          name="txtEmail"
          className="form-control mb-4"
          autocomplete="off"
          placeholder="CORREO"
          value={formData.txtEmail}
          onChange={handleChange}
        />
      </div>
      <div className="col-6">
        <input
          type="text"
          id="txtWhatsapp"
          name="txtWhatsapp"
          className="form-control mb-4"
          autocomplete="off"
          placeholder="WHATSAPP"
          value={formData.txtWhatsapp}
          onChange={handleChange}
        />
      </div>
      <div className="col-6">
        <input
          type="text"
          id="txtCompany"
          name="txtCompany"
          className="form-control mb-4"
          autocomplete="off"
          placeholder="EMPRESA"
          value={formData.txtCompany}
          onChange={handleChange}
        />
      </div>
      <div className="col-6">
        <input
          type="text"
          id="txtWeb"
          name="txtWeb"
          className="form-control mb-4"
          autocomplete="off"
          placeholder="WEB"
          value={formData.txtWeb}
          onChange={handleChange}
        />
      </div>
      <div className="col-12">
        <button className="btn w-100 py-2" style={styles.button}>
          CONSULTAR AHORA
        </button>
        <small className='d-none'>Mensaje enviado, lo contactaremos a la brevedad.</small>
      </div>
    </form>
  );
};

export default ContactForm;