import React from 'react';
import './our-phases-content.component.style.css';
import imageOurPhases from '../../img/our-phases.svg'

const ContentOurPhases = () => {
    return (

        <div className="container content-our-phases">
            <div className="row">
                <div className="col-12 text-center mt-5 mb-3 py-5">
                    <h2>Conoce nuestras 5 fases</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <div className="row">
                        <div className="col-6 pb-3">
                            <h3>1. Captación</h3>
                            <p className='py-3'>Realizamos campañas en instagram, google, facebook, youtube, tiktok enfocadas a la captación segmentada para la generación de leads genuinos.</p>
                        </div>
                        <div className="col-6 pb-3">
                            <h3>2. Data Warehouse</h3>
                            <p className='py-3'>Realizamos campañas en instagram, google, facebook, youtube, tiktok enfocadas a la captación segmentada para la generación de leads genuinos.</p>
                        </div>
                        <div className="col-6 pb-3">
                            <h3>3. Base de datos</h3>
                            <p className='py-3'>Te proveemos de datos provenientes de nuestro almacén de datos propio, el cual recibe un proceso de actualización diario que cruza información con distintos orígenes, logrando un resultado robusto, actualizado y fidedigno.</p>
                        </div>
                        <div className="col-6 pb-3">
                            <h3>4. Inteligencia</h3>
                            <p className='py-3'>A través de tableros dinámicos podrás analizar la información recopilada, crear proyecciones y tomar decisiones con inteligencia de negocios.</p>
                        </div>
                        <div className="col-6 pb-3">
                            <h3>5. Customer services</h3>
                            <p className='py-3'>Un ejecutivo de cuenta es asignado quien se ocupará de entender, analizar y acompañarte en la entrega de datos personalizados.</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-5 mx-auto">
                    <img src={imageOurPhases} alt="Nuestras 5 fases" className='img-fluid w-75' />
                </div>
            </div>

        </div>

    );
};

export default ContentOurPhases;
