import React from 'react';
import servicesDashboard from '../../img/services-dashboard.png';
import './services-banner.style.css';

const ServicesBanner = () => {
    return (
        <div className="services-banner">
            <div className="container pb-5">
                <div className="row py-5">
                   <div className="col-12 col-sm-6">
                        <h2 className='pt-3 pt-sm-5 w-50 d-none d-sm-block'>Tu gestor de contactos inteligente</h2>
                        <h2 className='pt-3 pt-sm-5 w-100 d-block d-sm-none'>Tu gestor de contactos inteligente</h2>
                    <h1 className='pt-3 pb-5'>Fase 5</h1>
                    <p className='pb-5'>Crea tu propia base de datos de big data. Estos datos se nutren desde campañas de marketing, Google adwords, importación de datos propios, landing pages, cruces con datos públicos y redes sociales.</p>
                        <a href='/contacto' className='btn btn-primary px-3 py-2'>Comenzar gratis</a>
                   </div>
                   <div className="col-12 col-sm-6">
                        <img src={servicesDashboard} alt="Fase 5 services" className='img-fluid pt-5 p-sm-3'/>
                   </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesBanner;
