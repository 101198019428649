import React from 'react';
import './services-description.style.css';
const ServicesDescription = () => {
    return (

        <div className="services-description">
            <div className="row h-100">
                <div className="col-12 col-sm-6 p-5 d-flex align-items-center">
                    <h2 className='text-center mx-auto'>Múltiples herramientas</h2>
                </div>
                <div className="col-12 col-sm-6 block p-5  align-items-center">
                    <ul>
                        <li>
                            <h3>Millones de datos a un clic</h3>
                            <p className='pb-4'>Poseemos una amplia base de datos resultado de campañas de captación de leads propia, que podrás adquirir para ampliar tu base de datos. La misma se encuentra segmentada por nombre, correo, edad, documento, teléfono, gustos, bancarización, entre otros.</p>
                        </li>
                        <li>
                            <h3>Segmentación por gustos e intereses</h3>
                            <p className='pb-4'>Nuestro algoritmo identifica aquellos perfiles que tienen gustos similares a tus productos y servicios en base a Instagram. Podrás enviar mensajes directos e invitaciones para que te sigan, llegando con tu propuesta de valor a un público objetivo.</p>
                        </li>
                        <li>
                            <h3>Envío de mailing</h3>
                            <p className='pb-4'>Desde la propia herramienta, podrás realizar mailing a tu base de datos segmentada.</p>
                        </li>
                        <li>
                            <h3>Captura de datos</h3>
                            <p className='pb-4'>Recupera contactos desde tu web y landing page a través de formularios embebidos. Importa datos a través de excel o csv. Podrás nutrir tu base de datos desde distintos orígenes de información.</p>
                        </li>
                        <li>
                            <h3>Tableros de big data</h3>
                            <p className='pb-4'>Creamos potentes tableros de visualización de big data con la tecnología Amazon QuickSight. Podrás identificar segmentos de clientes rentables a través de análisis de datos demográficos y comportamentales, permitiendo llegar con una estrategia de marketing personalizada.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ServicesDescription;
