import React from 'react';
import './introduction.styles.css'

const IntroductionSection = () => {
  return (
    <div className="container introduction">
      <div className="row">
        <div className="col-12 text-center py-5">
          <p className="pt-5 pb-5">Nuestro algoritmo procesa datos de contacto de personas todos los días, donde podrás segmentar de forma demográfica y por <u>intereses</u>.</p>
          <a href='/contacto' className="btn btn-primary contact-button mb-5 py-2 px-4">COMENZAR GRATIS</a>
        </div>
      </div>
    </div>
  );
};

export default IntroductionSection;
