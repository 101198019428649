import React from 'react';
import './action-bar.style.css';
import data from '../../data/data.json'

const ActionBar = () => {
	return (
		<div className="action-bar">
			<div className='container'>
				<div className="row">
					<div className="col-12">
						<ul className="my-0 px-0">
							<li className="slogan pe-2 ps-0 py-2">Atención al cliente <i class="fa-regular fa-envelope"></i> <a href={"mailto:" + data.email} aria-label="mail"> {data.email}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ActionBar;
