import React from 'react';
import './terms-description.style.css';

const ServicesDescription = () => {
    const currentYear = new Date().getFullYear();
    
    return (

        <div className="terms-description container">
            <div className="row">
                <div className="col-12">
                    <h1 className='text-center py-5'>Términos y condiciones de uso</h1>
                </div>
                <div className="row">
                    <div className="col-12 pb-5">
                        <p>Antes de utilizar nuestros servicios, te pedimos que leas detenidamente estos Términos y Condiciones. Al acceder y utilizar nuestro sitio web, aceptas estar sujeto/a a los términos y condiciones descritos a continuación.</p>

                        <h2 className='py-4'>1. Uso del Sitio Web</h2>

                        <p>1.1 <strong>Aceptación de Términos:</strong> Al acceder y utilizar nuestro sitio web, aceptas cumplir con estos Términos y Condiciones y todas las leyes y regulaciones aplicables.</p>
                        <p>1.2 <strong>Edad Mínima:</strong> Debes tener al menos 18 años para utilizar nuestros servicios. Si eres menor de edad, se requiere el consentimiento de un padre o tutor.</p>

                        <h2 className='py-4'>2. Registro y Cuentas de Usuario</h2>

                        <p>2.1 <strong>Información de Registro:</strong> Al registrarte, proporcionas información precisa y completa. Es tu responsabilidad mantener la confidencialidad de tu cuenta y contraseña.</p>
                        <p>2.2 <strong>Uso No Autorizado:</strong> Eres responsable de todas las actividades realizadas a través de tu cuenta. Debes notificar cualquier uso no autorizado de tu cuenta de inmediato.</p>

                        <h2 className='py-4'>3. Privacidad</h2>

                        <p>Consulta nuestra <a href="#enlace-privacidad">Política de Protección y Privacidad de Datos</a> para entender cómo manejamos la información personal.</p>


                        <h2 className='py-4'>4. Propiedad Intelectual</h2>

                        <p>4.1 <strong>Derechos de Autor:</strong> El contenido del sitio web, incluyendo textos, gráficos, logotipos, imágenes y software, está protegido por derechos de autor.</p>
                        <p>4.2 <strong>Uso Permitido:</strong> Puedes descargar, imprimir y copiar el contenido para uso personal, no comercial. No se permite la reproducción, distribución o modificación sin autorización.</p>

                        <h2 className='py-4'>5. Responsabilidades del Usuario</h2>

                        <p>5.1 <strong>Uso Adecuado:</strong> No debes utilizar el sitio web para actividades ilegales, difamatorias, ofensivas o que violen los derechos de terceros.</p>
                        <p>5.2 <strong>Integridad del Sistema:</strong> No debes intentar eludir la seguridad del sitio web ni realizar acciones que puedan dañar, interferir o perjudicar su funcionamiento.</p>

                        <h2 className='py-4'>6. Modificaciones y Terminación</h2>

                        <p>6.1 <strong>Modificaciones:</strong> Nos reservamos el derecho de modificar o interrumpir el sitio web y servicios en cualquier momento, sin previo aviso.</p>
                        <p>6.2 <strong>Terminación:</strong> Podemos rescindir tu acceso al sitio web si violas estos Términos y Condiciones.</p>

                        <h2 className='py-4'>7. Limitación de Responsabilidad</h2>

                        <p>7.1 <strong>Uso bajo tu Propio Riesgo:</strong> El uso del sitio web es bajo tu propio riesgo. No garantizamos la disponibilidad, exactitud o fiabilidad del contenido.</p>
                        <p>7.2 <strong>Daños y Perjuicios:</strong> No somos responsables de daños directos, indirectos, incidentales, consecuentes u otros tipos de pérdidas derivadas del uso del sitio web.</p>

                        <h2 className='py-4'>8. Disposiciones Generales</h2>

                        <p>8.1 <strong>Ley Aplicable:</strong> Estos Términos y Condiciones se rigen por las leyes de la República Argentina.</p>
                        <p>8.2 <strong>Modificaciones:</strong> Estos Términos y Condiciones pueden ser modificados en cualquier momento. Se te notificarán cambios significativos.</p>

                        <p>Al utilizar nuestros servicios, confirmas que has leído, comprendido y aceptado estos Términos y Condiciones.</p>

                        <p className='py-4'><strong>© {currentYear} Fase 5</strong> - Todos los derechos reservados. <a href="/politica-de-privacidad">Política de Protección y Privacidad de Datos</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesDescription;
