import React from 'react';
import './our-phases-separator.component.style.css';

const OurPhasesSeparator = () => {
    return (
        <div className="our-phases-separator">
        <div className="container">
            <div className="row">
                <div className="col-12 text-center py-5">
                    <h3 className="pt-5 pb-5">¿Preparado para datos inteligentes?</h3>
                    <a href='/contacto' className="btn btn-primary contact-button mb-5 py-2 px-4">COMENZAR GRATIS</a>
                </div>
            </div>
        </div>
        </div>
    );
};

export default OurPhasesSeparator;
