import React from 'react';
import './contact-description.component.style.css';
import imageQuote from '../../img/contact-us_quote.svg';
const ContactDescription = () => {
    return (
        <div className="contact-description">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-sm-6 bg-contact p-5">
                        <h1 className='py-5'>Contactanos</h1>
                        <p className='pb-3'>Obtené datos personalizados inmediatamente.</p>
                        <p className='pb-3'>Completa el siguiente formulario y te contactaremos para agendar una llamada, entender tu necesidad y enviarte una propuesta personalizada.</p>
                        <img class="pt-3 pb-5" src={imageQuote} alt=""></img>
                        <p>LAS EMPRESAS QUE VENDEN POR INTERNET NOS ELIGEN COMO SU PRINCIPAL PROVEEDOR DE CONTACTOS.</p>
                    </div>
                    <div className="col-12 col-sm-6 p-5">
                        <form action="" method="post" className='row'>
                            <div className='col-12'>
                                <input type="text" id="txtName" name="txtName" className="form-control mb-4" placeholder='NOMBRE' />
                            </div>
                            <div className="col-6">
                                <input type="email" id="txtEmail" name="txtEmail" className="form-control mb-4 " placeholder='CORREO' />
                            </div>
                            <div className="col-6">
                                <input type="text" id="txtWhatsapp" name="txtWhatsapp" className="form-control mb-4" placeholder='WHATSAPP' />
                            </div>
                            <div className="col-6">
                                <input type="text" id="txtCompany" name="txtCompany" className="form-control mb-4" placeholder='EMPRESA' />
                            </div>
                            <div className="col-6">
                                <input type="url" id="txtWeb" name="txtWeb" className="form-control mb-4" placeholder='WEB' />
                            </div>
                            <div className="col-12">
                                <textarea name="txtMessage" id="txtMessage" rows="5" className='form-control' placeholder='MENSAJE'></textarea>
                            </div>
                            <div className="col-12">
                                <button className='btn w-100 py-2 mt-4' type="submit">CONSULTAR AHORA</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactDescription;
