import React from 'react';
import imageBannerPhasesLeft from '../../img/our-phases-banner-left.svg';
import imageBannerPhasesRight from '../../img/our-phases-banner-right.svg';
import './banner-our-phases.style.css';

const BannerOurPhases = () => {
    return (
        <>
            <div className="row banner-our-phases">
                <div className="col-3 d-sm-block d-none">
                    <img src={imageBannerPhasesLeft} className='float-start img-fluid' />
                </div>
                <div className='col-12 col-sm-6 text-center p-4 pb-5 py-sm-5'>
                    <h1 className='py-5'>Los datos son la materia prima de toda empresa inteligente</h1>
                    <p>Capturamos, procesamos y analizamos datos para empresas que buscan realizar campañas de ventas y comunicación a través de bases de datos segmentadas.</p>
                </div>
                <div className="col-3 d-sm-block d-none">
                    <img src={imageBannerPhasesRight} className='float-end img-fluid' />
                </div>
            </div>
        </>
    );
};

export default BannerOurPhases;
