import React from 'react';
import './privacy-policy-description.style.css';

const PrivacyPolicyDescription = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className="privacy-policy container">
            <div className="row">
                <div className="col-12">
                    <h1 className='text-center py-5'>Política de protección y privacidad de datos</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12 pb-5">
                    <p>A continuación, se describe nuestra Política de Protección y Privacidad de Datos. Esta política detalla cómo recopilamos, utilizamos, divulgamos, transferimos y almacenamos la información personal de los usuarios de nuestro sitio web.</p>

                    <h2 className='py-4'>1. Recopilación de Datos</h2>

                    <p>1.1 <strong>Datos Personales Recopilados:</strong> Recopilamos información personal, como nombres, direcciones de correo electrónico, números de teléfono y otra información necesaria para proporcionar nuestros servicios.</p>
                    <p>1.2 <strong>Datos de Navegación:</strong> Recopilamos información sobre la actividad de navegación de los usuarios en nuestro sitio web, incluyendo direcciones IP, cookies y otros datos de navegación.</p>

                    <h2 className='py-4'>2. Uso de Datos</h2>

                    <p>2.1 <strong>Propósito:</strong> Utilizamos la información recopilada para proporcionar y mejorar nuestros servicios, responder a consultas, personalizar la experiencia del usuario y garantizar el cumplimiento de las leyes y regulaciones aplicables.</p>
                    <p>2.2 <strong>Comunicaciones:</strong> Podemos utilizar la información de contacto proporcionada para enviar actualizaciones, noticias relevantes y promociones.</p>

                    <h2 className='py-4'>3. Almacenamiento y Seguridad</h2>

                    <p>3.1 <strong>Almacenamiento:</strong> Los datos personales se almacenan de manera segura y se accede a ellos solo para los fines establecidos en esta política.</p>
                    <p>3.2 <strong>Retención de Datos:</strong> Retenemos la información personal durante el tiempo necesario para cumplir con los fines para los cuales se recopiló, a menos que se requiera un período de retención más largo por obligación legal.</p>

                    <h2 className='py-4'>4. Compartir Datos</h2>

                    <p>4.1 <strong>Terceros:</strong> No compartimos información personal con terceros sin el consentimiento expreso del usuario, excepto cuando sea necesario para proporcionar los servicios solicitados o cumplir con la ley.</p>

                    <h2 className='py-4'>5. Derechos del Usuario</h2>

                    <p>5.1 <strong>Acceso y Rectificación:</strong> Los usuarios tienen derecho a acceder, corregir y eliminar sus datos personales.</p>
                    <p>5.2 <strong>Retirada de Consentimiento:</strong> Los usuarios tienen el derecho de retirar su consentimiento en cualquier momento para el procesamiento de sus datos personales.</p>

                    <h2 className='py-4'>6. Cambios en la Política</h2>

                    <p>Nos reservamos el derecho de modificar esta política en cualquier momento. Se notificarán cambios significativos a los usuarios a través de los medios apropiados.</p>

                    <h2 className='py-4'>7. Contacto</h2>

                    <p>Para cualquier pregunta o inquietud relacionada con esta política, los usuarios pueden ponerse en contacto con nosotros a través de sales@fasecinco.com.</p>

                    <p>Al utilizar nuestros servicios, los usuarios aceptan las prácticas descritas en esta Política de Protección y Privacidad de Datos.</p>

                    <p className='py-4'><strong>© {currentYear} Fase 5</strong> - Todos los derechos reservados. <a href="/terminos-y-condiciones">Términos y Condiciones de uso</a></p>
                </div>
            </div>
        </div >
    );
};

export default PrivacyPolicyDescription;
