
import React from 'react';
import Header from '../components/header/header.component';
import Footer from '../components/footer/footer.component';

function BlogPage() {

  return (
    <>
      <Header/>

      <Footer/>
    </>
  );
}


export default BlogPage;
