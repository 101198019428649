
import React from 'react';
import Header from '../components/header/header.component';
import Footer from '../components/footer/footer.component';
import ContactDescription from '../components/content/contact-description.component';

function ContactPage() {

  return (
    <>
      <Header/>
      <ContactDescription/>
      <Footer/>
    </>
  );
}


export default ContactPage;
