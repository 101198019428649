
import React from 'react';
import Header from '../components/header/header.component';
import Content from '../components/content/content.component';
import Footer from '../components/footer/footer.component';

function HomePage() {

  return (
    <>
      <Header/>
      <Content/>
      <Footer/>
    </>
  );
}


export default HomePage;
