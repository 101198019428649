
import React from 'react';
import Header from '../components/header/header.component';
import PrivacyPolicyDescription from '../components/content/privacy-policy-description.component';
import Footer from '../components/footer/footer.component';

function TermsAndConditions() {

    return (
        <>
            <Header />
            <PrivacyPolicyDescription />
            <Footer />
        </>
    );
}


export default TermsAndConditions;
